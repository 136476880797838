import { Ban } from "lucide-react";

interface CancelByInfoProps {
  cancelBy?: string;
  cancelReason?: string;
}

export default function CancelByInfo({
  cancelBy,
  cancelReason,
}: CancelByInfoProps) {
  return (
    <div className="flex items-center gap-2 rounded-lg border border-sidebar-prtext-sidebar-primary p-3">
      <Ban className="h-4 w-4 text-sidebar-primary" />
      <div className="flex flex-col">
        <span className="text-sm font-medium">
          Canceled by{" "}
          <span className="text-sidebar-primary font-semibold">{cancelBy}</span>
        </span>
        {cancelReason && (
          <span className="text-sm text-muted-foreground">{cancelReason}</span>
        )}
      </div>
    </div>
  );
}
