import { Mail, MessageCircleIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Separator } from "../ui/separator";

interface ContactMethod {
  type: "phone" | "email";
  value: string;
  isPrimary?: boolean;
}

interface ContactButtonProps {
  contactMethods: ContactMethod[];
  type?: "phone" | "email";
}

export default function ContactButton({
  contactMethods,
  type = "phone",
}: ContactButtonProps) {
  const handleContact = (method: ContactMethod) => {
    if (method.type === "phone") {
      window.open(`https://wa.me/${method.value}`, "_blank");
    } else if (method.type === "email") {
      window.location.href = `mailto:${method.value}`;
    }
  };

  const buttonText = type === "phone" ? "Direct WA" : "Email";
  const ButtonIcon = type === "phone" ? MessageCircleIcon : Mail;

  if (contactMethods.length === 1) {
    return (
      <Button
        variant="secondary"
        size="sm"
        className="bg-sidebar-accent text-secondary hover:bg-sidebar-accent"
        onClick={() => handleContact(contactMethods[0])}
      >
        <ButtonIcon className="mr-2 h-4 w-4" />
        {buttonText}
      </Button>
    );
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="secondary"
          size="sm"
          className="bg-sidebar-accent text-secondary hover:bg-sidebar-accent"
        >
          <ButtonIcon className="mr-2 h-4 w-4" />
          {buttonText}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {contactMethods.map((method, index) => (
          <>
            <DropdownMenuItem
              key={method.value}
              onClick={() => handleContact(method)}
            >
              {method.isPrimary ? `${method.value}` : method.value}
            </DropdownMenuItem>
            {contactMethods.length > index + 1 && (
              <Separator className="bg-sidebar-accent" />
            )}
          </>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
