import axiosInstance from "@/api/axios-instance";

export async function getForms({
  page,
  search,
}: {
  page: number;
  search?: string;
}) {
  const response = await axiosInstance.get("/forms", {
    params: { page, search },
  });

  if (!response.status.toString().startsWith("2")) {
    throw new Error("forms is not loaded");
  }

  const { forms, nextPage, lastPage, page: currentPage, total } = response.data;
  return {
    forms,
    nextPage,
    lastPage,
    currentPage,
    total,
  };
}
