import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { ScrollArea } from "@/components/ui/scroll-area";
import { bundleStatusToVariant } from "@/helpers/bundle-status-to-variant";
import { Bundle } from "@/pages/bundles/types/bundle";

export default function BundleCard({ bundles }: { bundles: Array<Bundle> }) {
  return (
    <div className="md:w-[65vw] lg:w-[68vw] xl:w-[75vw] 2xl:w-[80vw]">
      <Card className="overflow-hidden">
        <CardHeader>
          <CardTitle className="text-3xl">Bundles</CardTitle>
          <CardDescription className="text-sm">
            All product bundles
          </CardDescription>
        </CardHeader>
        <ScrollArea className="h-[calc(100%-5rem)]">
          <CardContent className="space-y-10">
            {bundles.map((bundle) => (
              <Card key={bundle.id} className="overflow-hidden">
                <CardHeader className="bg-gray-100">
                  <div className="flex items-center justify-between">
                    <CardTitle className="text-lg">{bundle.name}</CardTitle>
                    <div className="flex gap-2">
                      <Badge variant={bundleStatusToVariant(bundle.status)}>
                        {bundle.status}
                      </Badge>
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <CardDescription>
                      {bundle.expires_in_month
                        ? `expires in ${bundle.expires_in_month} month(s)`
                        : "No Expiry"}
                    </CardDescription>
                    <div className="flex items-center justify-between">
                      <span className="text-sm text-muted-foreground"></span>
                      <span>
                        {bundle.session_quantity
                          ? `${bundle.session_quantity} session(s)`
                          : "no sessions"}
                      </span>
                    </div>
                  </div>
                </CardHeader>
                <CardContent className="grid gap-4 pt-4">
                  <div className="flex items-center justify-between">
                    <span className="text-sm text-muted-foreground"></span>
                    <span className="font-semibold text-xl">
                      ₹ {bundle.amount}
                    </span>
                  </div>
                </CardContent>
              </Card>
            ))}
          </CardContent>
        </ScrollArea>
      </Card>
    </div>
  );
}
