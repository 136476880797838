import ThreeDotsLoader from "@/components/common/three-dots-loader";
import useForms from "@/features/forms/hooks/use-forms";
import FormCard from "@/features/forms/ui/form-card";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Form } from "./types/form";
import { CardTitle } from "@/components/ui/card";
import CustomPaginationComponent from "@/components/common/custom-pagination-component";

export default function Forms() {
  const [searchParams] = useSearchParams();
  const initialPage = parseInt(searchParams.get("page") || "0", 10);
  const initialSearch = searchParams.get("search") || "";

  const [_page, setCurrentPage] = useState(initialPage);
  const navigate = useNavigate();
  const { isFetching, isLoading, isSuccess, data, error } = useForms(_page);

  if (error) {
    return <></>;
  }

  if (isFetching || isLoading || !data) {
    return <ThreeDotsLoader />;
  }

  const { forms, lastPage: lastSkip, currentPage, total } = data;
  const lastPage = lastSkip + 1;

  const handlePageChange = (page: number) => {
    if (page >= 0 && page < lastPage) {
      setCurrentPage(page);
      navigate(
        `?page=${page}${initialSearch ? `&search=${initialSearch}` : ""}`,
        { replace: true }
      );
    }
  };

  return (
    <div className="space-y-6 pt-6 px-6">
      {isSuccess && <FormCard forms={forms} />}

      {forms?.length ? (
        <CustomPaginationComponent
          currentPage={currentPage}
          lastPage={lastPage}
          onPageChange={handlePageChange}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
