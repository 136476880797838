import { useQuery } from "@tanstack/react-query";
import { getBundles } from "../services/get-bundles";
import { Bundle } from "@/pages/bundles/types/bundle";

interface PaginatedBundles {
  bundles: Array<Bundle>;
  lastPage: number;
  nextPage: number | null;
  currentPage: number;
}

export default function useBundles(page: number = 0) {
  const { data, isLoading, error, isFetching, isSuccess } =
    useQuery<PaginatedBundles>({
      queryKey: [
        "bundles",
        {
          page,
        },
      ],
      queryFn: () => getBundles({ page }),
    });

  return { data, isLoading, error, isFetching, isSuccess };
}
