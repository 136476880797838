export const bundleStatusToVariant = (status: string) => {
  switch (status.toUpperCase()) {
    case "ACTIVE":
      return "active";
    case "COMPLETED":
      return "completed";
    case "EXPIRED":
      return "expired";
    default:
      return "inactive";
  }
};
