import { LucideIcon } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface UserInfoItemProps {
  icon: LucideIcon;
  label?: string;
  value: string | number;
  className?: string;
}

export default function UserInfoItem({
  icon: Icon,
  label,
  value,
  className,
}: UserInfoItemProps) {
  const content = label ? `${label}: ${value}` : `${value}`;

  return (
    <div className={`flex items-center gap-2 ${className}`}>
      <Icon className="h-4 w-4 shrink-0" />
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="truncate">{content}</span>
        </TooltipTrigger>
        <TooltipContent>
          <p className="break-all">{content}</p>
        </TooltipContent>
      </Tooltip>
    </div>
  );
}
