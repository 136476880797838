import AppRouterProviders from "@/components/common/app-router-providers";
import Bundles from "@/pages/bundles/bundles";
import Coupons from "@/pages/coupons/coupon";
import Forms from "@/pages/forms/forms";
import Professionals from "@/pages/professionals/professional";
import { SingleSessionView } from "@/pages/sessions/session-details";
import Sessions from "@/pages/sessions/sessions";
import Submissions from "@/pages/submissions/submissions";
import UserDetails from "@/pages/users/user-details";
import Users from "@/pages/users/users";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from "react-router-dom";

export const useCreateAppRouter = () => {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route element={<AppRouterProviders />}>
        <Route path="users">
          <Route index element={<Users />} />
          <Route path=":id" element={<UserDetails />} />
        </Route>
        <Route path="sessions">
          <Route index element={<Sessions />} />
          <Route path=":id" element={<SingleSessionView />} />
        </Route>
        <Route path="forms">
          <Route index element={<Forms />} />
        </Route>
        <Route path="bundles">
          <Route index element={<Bundles />} />
        </Route>
        <Route path="coupons">
          <Route index element={<Coupons />} />
        </Route>
        <Route path="professionals">
          <Route index element={<Professionals />} />
        </Route>
        <Route path="submissions">
          <Route index element={<Submissions />} />
        </Route>

        <Route path="*" element={<Navigate to="users" />} />
      </Route>
    )
  );
};
