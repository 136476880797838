import { MessageSquareIcon, Phone } from "lucide-react";
import { Button } from "@/components/ui/button";

interface UserActionButtonsProps {
  onCall?: () => void;
  onWhatsApp?: () => void;
}

export default function UserActionButtons({
  onCall,
  onWhatsApp,
}: UserActionButtonsProps) {
  return (
    <div className="flex gap-2 mt-4 sm:mt-0">
      <Button
        variant="secondary"
        size="sm"
        className="bg-white  hover:bg-white/95"
      >
        <Phone className="mr-2 h-4 w-4" />
        Call
      </Button>
      <Button
        variant="secondary"
        size="sm"
        className="bg-gray-700 text-background hover:bg-gray-700/90"
      >
        <MessageSquareIcon className="mr-2 h-4 w-4" />
        WhatsApp
      </Button>
    </div>
  );
}
