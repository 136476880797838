import { useQuery } from "@tanstack/react-query";
import { getCoupons } from "../services/get-coupons";
import { Coupon } from "@/pages/coupons/types/coupon";

interface PaginatedCoupons {
  coupons: Array<Coupon>;
  lastPage: number;
  nextPage: number | null;
  currentPage: number;
  total: number;
}

export default function useCoupons(page: number = 0, search?: string) {
  const { data, isLoading, error, isFetching, isSuccess } =
    useQuery<PaginatedCoupons>({
      queryKey: [
        "coupons",
        {
          page,
          search,
        },
      ],
      queryFn: () => getCoupons({ page, search }),
    });

  return { data, isLoading, error, isFetching, isSuccess };
}
