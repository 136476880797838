import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { formatInCommaSeparated } from "@/helpers/format-items";
import { Link } from "react-router-dom";
import ContactButton from "@/components/common/button-dropdown";
import { MessageSquareIcon, Phone } from "lucide-react";

interface UserCardProps {
  user: any;
}

const UserCard: React.FC<UserCardProps> = ({ user }) => {
  const { full_name, phone_numbers, is_lead, emails, id } = user;
  return (
    <Card key={user.id} className="bg-secondary-background">
      <CardContent className="flex items-center justify-between p-4 md:w-[65vw] lg:w-[68vw] xl:w-[75vw] 2xl:w-[80vw]">
        <div>
          <div>
            <div className="flex items-start justify-between w-52">
              <h3 className="font-semibold pb-2">
                <Link
                  to={`/users/${id}`}
                  className="text-sidebar-primary hover:underline hover:text-text-sidebar-primary/80"
                >
                  {full_name.length ? full_name : "no_name"}
                </Link>
              </h3>
              {is_lead && (
                <Badge className="justify-items-end ml-4" variant="completed">
                  lead
                </Badge>
              )}
            </div>
            <div>
              <p className="font-medium">
                {formatInCommaSeparated(phone_numbers)}
              </p>
              <p className="text-sm text-muted-foreground">
                {formatInCommaSeparated(emails)}
              </p>
            </div>
          </div>
        </div>
        <div className="text-right">
          <div className="mt-2 space-x-2 text-sm">
            <Button
              variant="secondary"
              size="sm"
              className="bg-white  hover:bg-white/95"
            >
              <Phone className="mr-2 h-4 w-4" />
              Call
            </Button>
            <Button
              variant="secondary"
              size="sm"
              className="bg-gray-700 text-background hover:bg-gray-700/90"
            >
              <MessageSquareIcon className="mr-2 h-4 w-4" />
              WhatsApp
            </Button>
            <ContactButton
              contactMethods={phone_numbers.map((phone: string) => {
                return { type: "phone", value: phone };
              })}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default UserCard;
