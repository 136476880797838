import axiosInstance from "@/api/axios-instance";

export async function getUsers({
  page,
  search,
}: {
  page: number;
  search?: string;
}) {
  const response = await axiosInstance.get("/users", {
    params: { page, search },
  });

  if (!response.status.toString().startsWith("2")) {
    throw new Error("users is not loaded");
  }

  const { users, nextPage, lastPage, page: currentPage } = response.data;
  return {
    users,
    nextPage,
    lastPage,
    currentPage,
  };
}
