import { Link, useParams } from "react-router-dom";
import useUser from "@/features/users/hooks/use-user";
import ThreeDotsLoader from "@/components/common/three-dots-loader";
import UserInfoItem from "@/features/users/ui/user-info-item";
import UserActionButtons from "@/features/users/ui/user-action-buttons";
import GiftedByInfo from "@/features/users/ui/gifted-by-info";

import { CalendarDays, Phone, Mail, MapPin, Globe, User2 } from "lucide-react";
import { format } from "date-fns";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { ScrollArea } from "@/components/ui/scroll-area";
import { bundleStatusToVariant } from "@/helpers/bundle-status-to-variant";
import ContactButton from "@/components/common/button-dropdown";

export default function UserDetails() {
  const { id } = useParams<{ id: string }>();
  const { data: user, isLoading, error, isFetching } = useUser(id!);

  if (error) {
    return <></>;
  }

  if (isFetching || isLoading || !user) {
    return <ThreeDotsLoader />;
  }

  const { phone_numbers, emails } = user;

  return (
    <div className="md:w-[65vw] lg:w-[68vw] xl:w-[75vw] 2xl:w-[80vw] py-6 space-y-6 px-6">
      <Card className="bg-sidebar-primary text-white">
        <CardContent className="p-6">
          <div className="flex flex-wrap items-start gap-6">
            <div className="flex-1 space-y-4">
              <div>
                <CardTitle className="text-2xl">
                  {user.full_name || "User Name"}
                </CardTitle>
              </div>
              <div
                className="grid gap-4 sm:grid-cols-1
               xl:grid-cols-2 2xl:grid-cols-3"
              >
                {phone_numbers.length &&
                  phone_numbers.map((phone_number) => {
                    return <UserInfoItem icon={Phone} value={phone_number} />;
                  })}
                {emails.length &&
                  emails.map((email) => {
                    return <UserInfoItem icon={Mail} value={email} />;
                  })}
                <UserInfoItem
                  icon={MapPin}
                  value={user.address ?? "<address>"}
                />
                <UserInfoItem
                  icon={Globe}
                  value={user.country ?? "<country>"}
                />
                <UserInfoItem
                  icon={User2}
                  label="Age"
                  value={user.age ?? "<age>"}
                />
                <UserInfoItem
                  icon={User2}
                  label="Sex"
                  value={user.sex ?? "<sex>"}
                />
                <UserInfoItem
                  icon={User2}
                  label="Pronouns"
                  value={user.pronouns ?? "<pronouns>"}
                />
                <UserInfoItem
                  icon={Globe}
                  label="Language"
                  value={user.language_preference ?? "<language>"}
                />
                <UserInfoItem
                  icon={User2}
                  label="Source"
                  value={user.discovery_source ?? "<discovery_source>"}
                />
                <UserInfoItem
                  icon={User2}
                  label="Score"
                  value={user.score ?? "<user_score>"}
                />
                <UserInfoItem
                  icon={User2}
                  label="Risk"
                  value={user.risk_category ?? "<risk_category>"}
                />
                <UserInfoItem
                  icon={User2}
                  label="Type"
                  value={user.type ?? "<user_type>"}
                />
                <UserInfoItem
                  icon={User2}
                  label="Lead"
                  value={user.is_lead ? "Yes" : "No"}
                />
              </div>
            </div>
            <UserActionButtons onCall={() => {}} onWhatsApp={() => {}} />
            <ContactButton
              contactMethods={phone_numbers.map((phone) => {
                return { type: "phone", value: phone };
              })}
            />
          </div>
        </CardContent>
      </Card>

      <Card className="h-[calc(100vh-16rem)] overflow-hidden">
        <CardHeader>
          <CardTitle>Bundle Usage</CardTitle>
          <CardDescription>All bundles linked to the user</CardDescription>
        </CardHeader>
        <ScrollArea className="h-[calc(100%-5rem)]">
          <CardContent className="space-y-4">
            {user.bundles_usage.map((bundle) => (
              <Card key={bundle.id} className="overflow-hidden">
                <CardHeader className="bg-gray-100">
                  <div className="flex items-center justify-between">
                    <Link
                      to={`/sessions?usage_id=${bundle.id}`}
                      className="text-sidebar-primary hover:underline hover:text-text-sidebar-primary/80"
                    >
                      <CardTitle className="text-lg">
                        {bundle.bundle.name}
                      </CardTitle>
                    </Link>
                    <div className="flex gap-2">
                      <Badge variant={bundleStatusToVariant(bundle.status)}>
                        {bundle.status}
                      </Badge>
                      {bundle.is_gifted && <Badge variant="gifted">GIFT</Badge>}
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <CardDescription>
                      {format(new Date(bundle.start_date), "PPP")} -{" "}
                      {format(new Date(bundle.end_date), "PPP")}
                    </CardDescription>
                    <div className="flex items-center justify-between">
                      <span className="text-sm text-muted-foreground"></span>
                      <span>₹ {bundle.bundle.amount}</span>
                    </div>
                  </div>
                </CardHeader>
                <CardContent className="grid gap-4 pt-4">
                  <div className="flex items-center justify-between">
                    <span className="text-sm text-muted-foreground"></span>
                    <span className="font-semibold text-xl">
                      {bundle.utilized_session_quantity} /{" "}
                      {bundle.total_session_quantity}
                    </span>
                  </div>
                  {bundle.is_gifted && bundle.gifter && (
                    <GiftedByInfo
                      gifter={bundle.gifter}
                      messageFromGifter={bundle.message_from_gifter}
                    />
                  )}
                </CardContent>
              </Card>
            ))}
            {user.bundles_usage.length === 0 && (
              <Card>
                <CardContent className="flex flex-col items-center justify-center py-8">
                  <CalendarDays className="h-8 w-8 text-muted-foreground" />
                  <p className="mt-2 text-center text-sm text-muted-foreground">
                    No bundles found
                  </p>
                </CardContent>
              </Card>
            )}
          </CardContent>
        </ScrollArea>
      </Card>
    </div>
  );
}
