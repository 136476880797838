import { Badge } from "@/components/ui/badge";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Form } from "@/pages/forms/types/form";
import { format } from "date-fns";

export default function FormCard({ forms }: { forms: Form[] }) {
  return (
    <div className="md:w-[65vw] lg:w-[68vw] xl:w-[75vw] 2xl:w-[80vw]">
      <Card className="overflow-hidden">
        <CardHeader>
          <CardTitle className="text-3xl">Forms</CardTitle>
          <CardDescription className="text-sm">All Forms</CardDescription>
        </CardHeader>
        <ScrollArea className="h-[calc(100%-5rem)]">
          <CardContent className="space-y-10">
            {forms.map((form) => (
              <Card key={form.source_id}>
                <CardHeader className="space-y-4 bg-gray-100">
                  <div className="flex items-start justify-between">
                    <div className="space-y-1">
                      <CardTitle className="text-2xl font-bold">
                        {form.name}
                      </CardTitle>
                      <CardDescription>
                        Department: {form.department}
                      </CardDescription>
                    </div>
                    <Badge
                      variant={form.status === "ACTIVE" ? "active" : "inactive"}
                      className="uppercase"
                    >
                      {form.status}
                    </Badge>
                  </div>
                </CardHeader>
                <CardContent className="space-y-6">
                  <div className="grid gap-4">
                    <div className="grid gap-2">
                      <h3 className="font-semibold">Form Details</h3>
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <p className="text-sm font-medium text-gray-500">
                            Source ID
                          </p>
                          <p className="text-sm font-mono">{form.source_id}</p>
                        </div>
                        <div>
                          <p className="text-sm font-medium text-gray-500">
                            Workspace Name
                          </p>
                          <p className="text-sm">{form.workspace_name}</p>
                        </div>
                      </div>
                    </div>

                    <div className="grid gap-2">
                      <h3 className="font-semibold">Timestamps</h3>
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <p className="text-sm font-medium text-gray-500">
                            Created
                          </p>
                          <p className="text-sm">
                            {format(new Date(form.created_at), "PPP p")}
                          </p>
                        </div>
                        <div>
                          <p className="text-sm font-medium text-gray-500">
                            Last Updated
                          </p>
                          <p className="text-sm">
                            {format(new Date(form.updated_at), "PPP p")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </CardContent>
        </ScrollArea>
      </Card>
    </div>
  );
}
