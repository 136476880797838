import axios from "axios";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: (import.meta.env.VITE_BACKEND_URL as string) ?? "",
});

// Add a request interceptor to attach the signature header
axiosInstance.interceptors.request.use(
  (config) => {
    const signature = (import.meta.env.VITE_SIGNATURE as string) ?? "";
    config.headers["x-rocket-health-signature"] = signature;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
