import axiosInstance from "@/api/axios-instance";

export async function getSession(id: string) {
  const response = await axiosInstance.get(`/sessions/${id}`);

  if (!response.status.toString().startsWith("2")) {
    throw new Error("session is not loaded");
  }

  return response.data;
}
