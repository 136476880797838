import { Gift } from "lucide-react";
import { Link } from "react-router-dom";

interface Gifter {
  id: string;
  full_name: string;
}

interface GiftedByInfoProps {
  gifter: Gifter;
  messageFromGifter?: string | null;
}

export default function GiftedByInfo({
  gifter,
  messageFromGifter,
}: GiftedByInfoProps) {
  return (
    <div className="flex items-center gap-2 rounded-lg border border-sidebar-prtext-sidebar-primary p-3">
      <Gift className="h-4 w-4 text-sidebar-primary" />
      <div className="flex flex-col">
        <span className="text-sm font-medium">
          Gifted by{" "}
          <Link to={`/users/${gifter.id}`}>
            <span className="text-sidebar-primary font-semibold">
              {gifter.full_name}
            </span>
          </Link>
        </span>
        {messageFromGifter && (
          <span className="text-sm text-muted-foreground">
            {messageFromGifter}
          </span>
        )}
      </div>
    </div>
  );
}
