import { Outlet } from "react-router-dom";
import AppSideBar from "./app-sidebar";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient();

export default function AppRouterProviders() {
  return (
    <AppSideBar>
      <QueryClientProvider client={queryClient}>
        <Outlet />
        <ReactQueryDevtools />
      </QueryClientProvider>
    </AppSideBar>
  );
}
