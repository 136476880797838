const ThreeDotsLoader = () => {
  return (
    <div className="flex justify-center items-center h-screen md:w-[65vw] lg:w-[68vw] xl:w-[75vw] 2xl:w-[80vw]">
      <div className="flex items-center justify-center space-x-2">
        <div className="w-4 h-4 bg-sidebar-primary rounded-full animate-bounce delay-100"></div>
        <div className="w-4 h-4 bg-sidebar-primary rounded-full animate-bounce delay-200"></div>
        <div className="w-4 h-4 bg-sidebar-primary rounded-full animate-bounce delay-300"></div>
      </div>
    </div>
  );
};

export default ThreeDotsLoader;
