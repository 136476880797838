import { useQuery } from "@tanstack/react-query";
import { getForms } from "../services/get-forms";
import { Form } from "@/pages/forms/types/form";

interface PaginatedForms {
  forms: Array<Form>;
  lastPage: number;
  nextPage: number | null;
  currentPage: number;
  total: number;
}

export default function useForms(page: number = 0, search?: string) {
  const { data, isLoading, error, isFetching, isSuccess } =
    useQuery<PaginatedForms>({
      queryKey: [
        "forms",
        {
          page,
          search,
        },
      ],
      queryFn: () => getForms({ page, search }),
    });

  return { data, isLoading, error, isFetching, isSuccess };
}
