import axiosInstance from "@/api/axios-instance";

export async function getUser(id: string) {
  const response = await axiosInstance.get(`/users/${id}`);

  if (!response.status.toString().startsWith("2")) {
    throw new Error("user is not loaded");
  }

  return response.data;
}
