import axiosInstance from "@/api/axios-instance";

export async function getCoupons({
  page,
  search,
}: {
  page: number;
  search?: string;
}) {
  const response = await axiosInstance.get("/coupon", {
    params: { page, search },
  });

  if (!response.status.toString().startsWith("2")) {
    throw new Error("coupons are not loaded");
  }

  const {
    coupons,
    nextPage,
    lastPage,
    page: currentPage,
    total,
  } = response.data;
  return {
    coupons,
    nextPage,
    lastPage,
    currentPage,
    total,
  };
}
