import { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import useUsers from "../hooks/use-users";
import ThreeDotsLoader from "@/components/common/three-dots-loader";
import UserCard from "./user-card";
import SearchBar from "@/components/common/search-bar";
import CustomPaginationComponent from "@/components/common/custom-pagination-component";

const UserList = () => {
  const [searchParams] = useSearchParams();
  const initialPage = parseInt(searchParams.get("page") || "0", 10);
  const initialSearch = searchParams.get("search") || "";

  const [_page, setCurrentPage] = useState(initialPage);
  const navigate = useNavigate();
  const { isFetching, isLoading, isSuccess, data, error } = useUsers(
    _page,
    initialSearch
  );

  if (error) {
    return <></>;
  }

  if (isFetching || isLoading || !data) {
    return <ThreeDotsLoader />;
  }

  const { users, lastPage: lastSkip, currentPage } = data;
  const lastPage = lastSkip + 1;

  const handlePageChange = (page: number) => {
    if (page >= 0 && page < lastPage) {
      setCurrentPage(page);
      navigate(
        `?page=${page}${initialSearch ? `&search=${initialSearch}` : ""}`,
        { replace: true }
      );
    }
  };

  const handleSearch = (search: string) => {
    setCurrentPage(0);
    navigate(`?page=0${search ? `&search=${search}` : ""}`, { replace: true });
  };

  return (
    <div className="space-y-6 pt-6 px-6">
      <SearchBar
        initialSearch={initialSearch}
        onSearch={handleSearch}
        placeholder="Search Users.."
      />

      {isSuccess &&
        users.map((user: any) => <UserCard key={user.id} user={user} />)}

      {users?.length ? (
        <CustomPaginationComponent
          currentPage={currentPage}
          lastPage={lastPage}
          onPageChange={handlePageChange}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default UserList;
