import { format } from "date-fns";
import {
  CalendarIcon,
  Clock,
  MapPin,
  Phone,
  Mail,
  AlertCircle,
} from "lucide-react";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Progress } from "@/components/ui/progress";
import useSession from "@/features/sessions/hooks/use-session";
import { Link, useParams } from "react-router-dom";
import ThreeDotsLoader from "@/components/common/three-dots-loader";

export function SingleSessionView() {
  const { id } = useParams<{ id: string }>();
  const { data: session, isLoading, error, isFetching } = useSession(id!);

  if (error) {
    return <></>;
  }

  if (isFetching || isLoading || !session) {
    return <ThreeDotsLoader />;
  }

  const {
    name,
    status,
    start_time,
    end_time,
    usage_bundle,
    meeting_link,
    invitee_email,
    invitee_phone,
    invitee_full_name,
    cancel_reason,
    canceled_by,
    canceler_type,
    is_no_show,
    is_rescheduled,
    invitee_partner_email,
    invitee_partner_full_name,
    invitee_partner_phone,
  } = session;

  const formatDuration = (start: string, end: string) => {
    const startTime = new Date(start);
    const endTime = new Date(end);
    const durationInMinutes =
      (endTime.getTime() - startTime.getTime()) / (1000 * 60);
    return `${durationInMinutes} minutes`;
  };

  return (
    <div className="max-w-3xl mx-auto p-6">
      <Card className="md:w-[65vw] lg:w-[68vw] xl:w-[75vw] 2xl:w-[83vw]">
        <CardHeader>
          <div className="flex items-start justify-between">
            <div>
              <CardTitle className="text-2xl font-bold">{name}</CardTitle>
              <CardDescription className="mt-2">
                with{" "}
                <Link to={`/professional/${session?.professional_id}`}>
                  {session?.professional?.name}
                </Link>
                {session?.professional?.department &&
                  ` • ${session?.professional.department}`}
              </CardDescription>
            </div>
            <Badge variant={status === "active" ? "active" : "destructive"}>
              {status.toUpperCase()}
            </Badge>
            {is_no_show && (
              <Badge variant="completed" className="ml-1">
                NO SHOW
              </Badge>
            )}
            {is_rescheduled && (
              <Badge variant="gifted" className="ml-1">
                RESCHEDULED
              </Badge>
            )}
          </div>
        </CardHeader>
        <CardContent className="space-y-6">
          {/* Time and Location */}
          <div className="space-y-4">
            <div className="flex items-center gap-2">
              <CalendarIcon className="h-5 w-5 text-muted-foreground" />
              <span>{format(new Date(start_time), "EEEE, MMMM d, yyyy")}</span>
            </div>
            <div className="flex items-center gap-2">
              <Clock className="h-5 w-5 text-muted-foreground" />
              <span>
                {format(new Date(start_time), "h:mm a")} -{" "}
                {format(new Date(end_time), "h:mm a")} (
                {formatDuration(start_time, end_time)})
              </span>
            </div>
            {session?.location?.join_url && (
              <div className="flex items-center gap-2">
                <MapPin className="h-5 w-5 text-muted-foreground" />
                <span>{session?.location?.join_url}</span>
              </div>
            )}
          </div>

          {/* Invitee Information */}
          <div className="border rounded-lg p-4 space-y-3">
            <h3 className="font-semibold">Patient Information</h3>
            <div className="space-y-2">
              <p className="text-sm">{invitee_full_name}</p>
              <div className="flex items-center gap-2 text-sm text-muted-foreground">
                <Mail className="h-4 w-4" />
                <span>{invitee_email}</span>
              </div>
              {invitee_phone && (
                <div className="flex items-center gap-2 text-sm text-muted-foreground">
                  <Phone className="h-4 w-4" />
                  <span>{invitee_phone}</span>
                </div>
              )}
            </div>
            <div className="space-y-2">
              {invitee_partner_email && invitee_partner_full_name && (
                <>
                  <p className="text-sm">{invitee_partner_full_name}</p>
                  <div className="flex items-center gap-2 text-sm text-muted-foreground">
                    <Mail className="h-4 w-4" />
                    <span>{invitee_partner_email}</span>
                  </div>
                </>
              )}

              {invitee_partner_phone && (
                <div className="flex items-center gap-2 text-sm text-muted-foreground">
                  <Phone className="h-4 w-4" />
                  <span>{invitee_partner_phone}</span>
                </div>
              )}
            </div>
          </div>

          {/* Usage Bundle Information */}
          {usage_bundle && (
            <div className="border rounded-lg p-4 space-y-3">
              <h3 className="font-semibold">Bundle Usage</h3>
              <div className="space-y-4">
                <div>
                  <p className="text-sm font-medium">
                    {usage_bundle.bundle.name}
                  </p>
                  <p className="text-sm text-muted-foreground">
                    {usage_bundle.utilized_session_quantity} of{" "}
                    {usage_bundle.total_session_quantity} sessions used
                  </p>
                </div>
                <Progress
                  value={
                    (usage_bundle.utilized_session_quantity /
                      usage_bundle.total_session_quantity) *
                    100
                  }
                  className="h-2"
                />
                {usage_bundle.is_gifted && usage_bundle.gifter && (
                  <div className="text-sm">
                    <p className="font-medium">
                      Gifted by: {usage_bundle.gifter.full_name}
                    </p>
                    {usage_bundle.message_from_gifter && (
                      <p className="text-muted-foreground mt-1">
                        "{usage_bundle.message_from_gifter}"
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Cancellation Information */}
          {status === "canceled" && (
            <div className="border rounded-lg p-4 bg-red-50 space-y-2">
              <div className="flex items-center gap-2 text-red-600">
                <AlertCircle className="h-5 w-5" />
                <h3 className="font-semibold">Cancelled</h3>
              </div>
              <p className="text-sm text-red-600">
                Cancelled by {canceled_by} ({canceler_type})
                {cancel_reason && (
                  <>
                    <br />
                    Reason: {cancel_reason}
                  </>
                )}
              </p>
            </div>
          )}
        </CardContent>
        <CardFooter className="flex justify-end space-x-2">
          {status === "active" && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="outline"
                    onClick={() => {
                      window.open(session?.location?.join_url, "_blank");
                    }}
                    disabled={!session?.location?.join_url}
                  >
                    Join Meeting
                  </Button>
                </TooltipTrigger>
                {!meeting_link && (
                  <TooltipContent>
                    <p>
                      Meeting link will be available closer to the session time
                    </p>
                  </TooltipContent>
                )}
              </Tooltip>
            </TooltipProvider>
          )}
        </CardFooter>
      </Card>
    </div>
  );
}
