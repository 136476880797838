function Logo() {
  return (
    <div className="flex justify-center text-xl tracking-widest">
      <span className=" font-medium text-gray-800">
        <span className=" font-semibold">Rocket</span> Health
      </span>
    </div>
  );
}

export default Logo;
