import axiosInstance from "@/api/axios-instance";

export async function getSessions({
  page,
  search,
  filter,
  usage_id,
}: {
  page: number;
  search?: string;
  filter?: string;
  usage_id?: string;
}) {
  const response = await axiosInstance.get("/sessions", {
    params: { page, search, filter, usage_id },
  });

  if (!response.status.toString().startsWith("2")) {
    throw new Error("sessions is not loaded");
  }

  const { sessions, nextPage, lastPage, page: currentPage } = response.data;
  return {
    sessions,
    nextPage,
    lastPage,
    currentPage,
  };
}
