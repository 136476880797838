import { format } from "date-fns";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import {
  Calendar,
  User,
  Phone,
  Mail,
  Users,
  Building2,
  Hash,
} from "lucide-react";
import { ScrollArea } from "@radix-ui/react-scroll-area";
import { Submission } from "@/pages/submissions/types/submission";

interface SubmissionCardProps {
  submissions: Submission[];
}

export function SubmissionCard({ submissions }: SubmissionCardProps) {
  return (
    <div className="md:w-[65vw] lg:w-[68vw] xl:w-[75vw] 2xl:w-[80vw]">
      <Card className="overflow-hidden">
        <CardHeader>
          <CardTitle className="text-3xl">Form Submissions</CardTitle>
          <CardDescription className="text-sm">
            All Form Submissions
          </CardDescription>
        </CardHeader>
        <ScrollArea className="h-[calc(100%-5rem)]">
          <CardContent className="space-y-10">
            {submissions.map((submission) => (
              <Card key={submission.id}>
                <CardHeader className="flex flex-row items-center justify-between">
                  <div>
                    <CardTitle className="text-xl">
                      {submission.form.name}
                    </CardTitle>
                    <CardDescription>
                      {submission.form.department}
                    </CardDescription>
                  </div>
                  <div className="flex gap-2">
                    <Badge variant="outline">{submission.response_type}</Badge>
                    <Badge
                      variant={
                        submission.form.status === "ACTIVE"
                          ? "active"
                          : "destructive"
                      }
                    >
                      {submission.form.status}
                    </Badge>
                  </div>
                </CardHeader>
                <CardContent>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="space-y-2">
                      {/* Primary Contact Information */}
                      {submission.answers.full_name && (
                        <div className="flex items-center gap-2">
                          <User className="h-4 w-4 text-muted-foreground" />
                          <span className="text-sm">
                            {submission.answers.full_name}
                          </span>
                        </div>
                      )}
                      {submission.answers.phone_number && (
                        <div className="flex items-center gap-2">
                          <Phone className="h-4 w-4 text-muted-foreground" />
                          <span className="text-sm">
                            {submission.answers.phone_number}
                          </span>
                        </div>
                      )}
                      {submission.answers.email && (
                        <div className="flex items-center gap-2">
                          <Mail className="h-4 w-4 text-muted-foreground" />
                          <span className="text-sm">
                            {submission.answers.email}
                          </span>
                        </div>
                      )}
                      {/* Partner Information if available */}
                      {(submission.answers.partner_full_name ||
                        submission.answers.partner_email ||
                        submission.answers.partner_phone_number) && (
                        <div className="flex items-center gap-2">
                          <Users className="h-4 w-4 text-muted-foreground" />
                          <span className="text-sm font-medium">
                            Partner Details:
                          </span>
                        </div>
                      )}
                      {submission.answers.partner_full_name && (
                        <div className="flex items-center gap-2 ml-6">
                          <User className="h-4 w-4 text-muted-foreground" />
                          <span className="text-sm">
                            {submission.answers.partner_full_name}
                          </span>
                        </div>
                      )}
                      {submission.answers.partner_phone_number && (
                        <div className="flex items-center gap-2 ml-6">
                          <Phone className="h-4 w-4 text-muted-foreground" />
                          <span className="text-sm">
                            {submission.answers.partner_phone_number}
                          </span>
                        </div>
                      )}
                      {submission.answers.partner_email && (
                        <div className="flex items-center gap-2 ml-6">
                          <Mail className="h-4 w-4 text-muted-foreground" />
                          <span className="text-sm">
                            {submission.answers.partner_email}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="space-y-2">
                      <div className="flex items-center gap-2">
                        <Calendar className="h-4 w-4 text-muted-foreground" />
                        <span className="text-sm">
                          Submitted At:{" "}
                          {format(new Date(submission.submitted_at), "PPp")}
                        </span>
                      </div>
                      <div className="flex items-center gap-2">
                        <Calendar className="h-4 w-4 text-muted-foreground" />
                        <span className="text-sm">
                          Landed At:{" "}
                          {format(new Date(submission.landed_at), "PPp")}
                        </span>
                      </div>
                      <div className="flex items-center gap-2">
                        <Building2 className="h-4 w-4 text-muted-foreground" />
                        <span className="text-sm">
                          Workspace: {submission.form.workspace_name}
                        </span>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </CardContent>
        </ScrollArea>
      </Card>
    </div>
  );
}
