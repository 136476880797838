import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FileText } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import ThreeDotsLoader from "@/components/common/three-dots-loader";
import CustomPaginationComponent from "@/components/common/custom-pagination-component";
import useSubmissions from "@/features/submissions/hooks/use-submissions";
import { SubmissionCard } from "@/features/submissions/ui/submission-card";

export default function Submissions() {
  const [searchParams] = useSearchParams();
  const initialPage = parseInt(searchParams.get("page") || "0", 10);
  const [_page, setCurrentPage] = useState(initialPage);
  const navigate = useNavigate();

  const { data, error, isFetching, isLoading, isSuccess } =
    useSubmissions(_page);

  if (error) {
    return <></>;
  }

  if (isFetching || isLoading || !data) {
    return <ThreeDotsLoader />;
  }

  const { submissions, lastPage: lastSkip, currentPage } = data;
  const lastPage = lastSkip + 1;

  const handlePageChange = (page: number) => {
    if (page >= 0 && page < lastPage) {
      setCurrentPage(page);
      navigate(`?page=${page}`, { replace: true });
    }
  };

  return (
    <div className="flex-1 overflow-auto">
      <div className="h-full p-8">
        <div className="space-y-6">
          {isSuccess ? (
            <SubmissionCard submissions={submissions} />
          ) : (
            <Card>
              <CardContent className="flex flex-col items-center justify-center py-8">
                <FileText className="h-8 w-8 text-muted-foreground" />
                <p className="mt-2 text-center text-sm text-muted-foreground">
                  No submissions found
                </p>
              </CardContent>
            </Card>
          )}
          {submissions?.length > 0 && (
            <CustomPaginationComponent
              currentPage={currentPage}
              lastPage={lastPage}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}
