import axiosInstance from "@/api/axios-instance";

export async function getBundles({ page }: { page: number }) {
  const response = await axiosInstance.get("/bundle", {
    params: { page },
  });

  if (!response.status.toString().startsWith("2")) {
    throw new Error("bundles are not loaded");
  }

  const { bundles, nextPage, lastPage, page: currentPage } = response.data;
  return {
    bundles,
    nextPage,
    lastPage,
    currentPage,
  };
}
