import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider,
} from "@/components/ui/sidebar";

import {
  BookOpenText,
  BookType,
  Home,
  Percent,
  ShoppingCart,
  Stethoscope,
  Users,
  Video,
} from "lucide-react";
import Logo from "./logo";
import { Separator } from "../ui/separator";
import { Link, useLocation } from "react-router-dom";
import { getBasePath } from "@/helpers/get-base-path";

// Menu items.
const items = [
  {
    title: "Users",
    url: "/users",
    icon: Users,
  },
  {
    title: "Sessions",
    url: "/sessions",
    icon: Video,
  },
  {
    title: "Submissions",
    url: "/submissions",
    icon: BookOpenText,
  },
  {
    title: "Professionals",
    url: "/professionals",
    icon: Stethoscope,
  },
  {
    title: "Coupons",
    url: "/coupons",
    icon: Percent,
  },
  {
    title: "Forms",
    url: "/forms",
    icon: BookType,
  },
  {
    title: "Bundles",
    url: "/bundles",
    icon: ShoppingCart,
  },
];

export default function AppSideBar({
  children,
}: {
  children: React.ReactNode;
}) {
  const location = useLocation();
  const basePath = getBasePath(location.pathname);
  return (
    <SidebarProvider>
      <Sidebar>
        <SidebarHeader>
          <Logo />
        </SidebarHeader>
        <Separator className="bg-sidebar-accent" />
        <SidebarContent>
          <SidebarGroup>
            <SidebarGroupLabel>Super Admin</SidebarGroupLabel>
            <SidebarGroupContent>
              <SidebarMenu>
                {items.map((item) => (
                  <SidebarMenuItem key={item.title}>
                    <SidebarMenuButton asChild isActive={item.url === basePath}>
                      <Link to={item.url}>
                        <item.icon />
                        <span className="text-lg text-sidebar-foreground">
                          {item.title}
                        </span>
                      </Link>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                ))}
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
        </SidebarContent>
      </Sidebar>
      <main>{children}</main>
    </SidebarProvider>
  );
}
