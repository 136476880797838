import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";

interface PaginationProps {
  currentPage: number;
  lastPage: number;
  onPageChange: (page: number) => void;
}

const CustomPaginationComponent: React.FC<PaginationProps> = ({
  currentPage,
  lastPage,
  onPageChange,
}) => {
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const delta = 2;
    let startPage = Math.max(currentPage - delta, 0);
    let endPage = Math.min(currentPage + delta, lastPage - 1);

    if (startPage > 1) {
      pageNumbers.push(0);
      pageNumbers.push("ellipsis");
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    if (endPage < lastPage - 2) {
      pageNumbers.push("ellipsis");
      pageNumbers.push(lastPage - 1);
    }

    return pageNumbers;
  };

  return (
    <Pagination>
      <PaginationContent className="mb-4">
        <PaginationItem>
          <PaginationPrevious
            href={`?page=${currentPage - 1}`}
            onClick={(e) => {
              e.preventDefault();
              onPageChange(currentPage - 1);
            }}
            isActive={currentPage > 0}
          />
        </PaginationItem>

        {renderPageNumbers().map((page, index) => (
          <PaginationItem key={index}>
            {page === "ellipsis" ? (
              <PaginationEllipsis />
            ) : (
              <PaginationLink
                href={`?page=${page}`}
                onClick={(e) => {
                  e.preventDefault();
                  onPageChange(page as number);
                }}
                isActive={page === currentPage}
              >
                {page}
              </PaginationLink>
            )}
          </PaginationItem>
        ))}

        <PaginationItem>
          <PaginationNext
            href={`?page=${currentPage + 1}`}
            onClick={(e) => {
              e.preventDefault();
              onPageChange(currentPage + 1);
            }}
            isActive={currentPage < lastPage - 1}
          />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );
};

export default CustomPaginationComponent;
