import { format } from "date-fns";
import { MoreHorizontal } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Badge } from "@/components/ui/badge";
import { Session } from "@/pages/sessions/types/session";
import CancelByInfo from "@/features/users/ui/canceled-by-info";
import { Link } from "react-router-dom";

interface SessionCardProps {
  session: Session;
  onCancel?: (id: string) => void;
  onReschedule?: (id: string) => void;
  onNoShow?: (id: string) => void;
}

export function SessionCard({
  session,
  onCancel,
  onReschedule,
  onNoShow,
}: SessionCardProps) {
  const {
    name,
    professional,
    status,
    start_time,
    end_time,
    invitee_email,
    invitee_full_name,
    is_no_show,
    is_rescheduled,
    invitee_phone,
    canceled_by,
    cancel_reason,
  } = session;
  return (
    <Card className="w-full">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2 bg-secondary-background">
        <div>
          <Link to={`/sessions/${session.id}`}>
            <CardTitle className="text-xl font-bold text-sidebar-primary">
              {name}
            </CardTitle>
          </Link>
          <p className="text-sm text-muted-foreground">
            with {professional?.name}
          </p>
        </div>
        <div>
          <Badge variant={status === "active" ? "active" : "destructive"}>
            {status.toUpperCase()}
          </Badge>
          {is_no_show && (
            <Badge variant="completed" className="ml-1">
              NO SHOW
            </Badge>
          )}
          {is_rescheduled && (
            <Badge variant="gifted" className="ml-1">
              RESCHEDULED
            </Badge>
          )}
        </div>
      </CardHeader>
      <CardContent className="bg-secondary-background">
        <div className="flex justify-between items-center">
          <div className="space-y-1">
            <p className="text-sm font-medium">
              {format(new Date(start_time), "EEEE, MMMM d, yyyy")}
            </p>
            <p className="text-sm text-muted-foreground">
              {format(new Date(start_time), "h:mm a")} -{" "}
              {format(new Date(end_time), "h:mm a")}
            </p>
          </div>
          <div className="text-right">
            <p className="text-sm font-medium">{invitee_full_name}</p>
            <p className="text-sm text-muted-foreground"> {invitee_phone} </p>
            <p className="text-sm text-muted-foreground">{invitee_email}</p>
          </div>
        </div>
      </CardContent>
      {status === "canceled" && (
        <CancelByInfo cancelBy={canceled_by} cancelReason={cancel_reason} />
      )}

      <CardFooter className="justify-end">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="sm">
              <MoreHorizontal className="h-4 w-4" />
              <span className="sr-only">Actions</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={() => onCancel?.(session.id)}>
              Cancel Session
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => onReschedule?.(session.id)}>
              Reschedule
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => onNoShow?.(session.id)}>
              Mark as No-Show
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </CardFooter>
    </Card>
  );
}
