import { useQuery } from "@tanstack/react-query";
import { getUsers } from "../services/get-users";

export default function useUsers(page: number = 0, search?: string) {
  const { data, isLoading, error, isFetching, isSuccess } = useQuery({
    queryKey: [
      "users",
      {
        page,
        search,
      },
    ],
    queryFn: () => getUsers({ page, search }),
  });

  return { data, isLoading, error, isFetching, isSuccess };
}
